import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

const Dropdown = ({
  name,
  label,
  className,
  styles,
  children,
  defaultValue,
  required,
  value,
  onChange,
  prompt,
}) => {
  return (
    <div
      className={classNames(
        'mb-3 focus-within:text-accent-500 text-gray-800',
        className
      )}
      css={[...(styles ?? [])]}
    >
      {label && (
        <label htmlFor={name} className="block ml-3 mb-1 font-bold">
          {label}
          {required && <span className="text-red-600">*</span>}
        </label>
      )}
      <select
        name={name}
        className="block appearance-none w-full py-2 px-3 border-2 border-gray-400 focus:border-accent-400 text-gray-700 focus:outline-none leading-tight rounded-md"
        defaultValue={defaultValue ?? ''}
        required={required}
        onChange={onChange}
        value={value}
      >
        {prompt && (
          <option disabled value="" className="text-gray-400">
            {prompt}
          </option>
        )}
        {children}
      </select>
    </div>
  )
}

export const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  styles: PropTypes.arrayOf(PropTypes.string),
  prompt: PropTypes.string,
}

Dropdown.propTypes = propTypes

export default Dropdown
