import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import ApplicationLayout from 'components/layouts/preliminary-application-layout'
import { usePreliminaryApplication } from 'contexts/preliminary-application-context'
import GeneralInfoForm from 'components/pages/preliminary-application/general-information-form'

export default ({ location }) => {
  const application = usePreliminaryApplication()
  const [formData, setFormData] = useState(undefined)
  const [errors, setErrors] = useState(undefined)

  useEffect(() => {
    if (application.initialized) {
      application.setStep('generalInformation')
      setFormData({ ...application.generalInformation.fields })
      setErrors({ ...application.generalInformation.errors })
    }
  }, [application.initialized])

  const handleFormChange = event => {
    const { name, value } = event.target

    switch (name) {
      // case 'applicantSignDate':
      // case 'coapplicantSignDate':
      //   setFormData({ ...formData, [name]: moment(value).toDate() })
      //   break

      case 'operateFulltime':
        setFormData({
          ...formData,
          [name]: value === 'true' ? true : value === 'false' ? false : '',
        })
        break

      default:
        setFormData({ ...formData, [name]: value })
    }
  }

  const handleFormSubmit = async event => {
    event.preventDefault()

    try {
      await application.update('generalInformation', formData)
      navigate('/preliminary-application/franchise-ownership')
    } catch (error) {
      // TODO:
      console.error('Error occured', error)
      window.alert('Something went wrong!')
    }
  }

  const handleGoBack = event => {
    event.preventDefault()
    navigate('/preliminary-application/educational-background')
  }

  console.log('form', formData)

  if (!application.initialized || !formData) {
    return <div>Loading...</div>
  }

  return (
    <ApplicationLayout
      step="General Information"
      stepNumber={4}
      location={location}
    >
      <GeneralInfoForm
        formData={formData}
        errors={errors}
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}
        onBack={handleGoBack}
      />
    </ApplicationLayout>
  )
}
