import React from 'react'

import ContainedButton from 'components/buttons/contained-button'
import Input, { InputGroup } from 'components/input'
import Textarea from 'components/textarea'
import Dropdown from 'components/dropdown'
import YesNoDropdown from 'components/dropdown/yesno-dropdown'

export default ({ formData, errors, onChange, onSubmit, onBack }) => {
  console.log('formData.operateFulltime', formData.operateFulltime)

  return (
    <form onChange={onChange} onSubmit={onSubmit}>
      <Textarea
        label="What is your timeframe for opening a Little Scholar franchise?"
        name="openTimeframe"
        type="text"
        error={errors.openTimeframe}
        defaultValue={formData.openTimeframe}
        required
      />

      <Input
        label="Who will have primary day-to-day responsibilities of operating your Little Scholar franchise?"
        name="operator"
        type="text"
        error={errors.operator}
        defaultValue={formData.operator}
        required
      />

      <YesNoDropdown
        label="Do you plan on operating your Little Scholar on a full-time basis as your primary means of income?"
        name="operateFulltime"
        prompt="Select an answer"
        defaultValue={formData.operateFulltime}
        error={errors.operateFulltime}
        required
      />

      <Input
        label="Do you have a preferred area to locate your Little Scholar franchise? (town, city, country)"
        name="preferredLocation"
        placeholder="Town, city, country"
        type="text"
        error={errors.preferredLocation}
        defaultValue={formData.preferredLocation}
        required
      />

      <Dropdown
        label="Will you be owning or renting the property?"
        name="propertyType"
        prompt="Select an answer"
        defaultValue={formData.propertyType}
        error={errors.propertyType}
        required
      >
        <option value="own">Own</option>
        <option value="rent">Rent</option>
      </Dropdown>

      <Input
        label="Address"
        name="address"
        placeholder="Please input in full address"
        type="text"
        error={errors.address}
        defaultValue={formData.address}
        required
      />

      <Input
        label="What is the square footage of the building?"
        name="squareFootage"
        type="text"
        error={errors.squareFootage}
        defaultValue={formData.squareFootage}
        required
      />

      <Input
        label="Total Assets/Wealth of this applicant:"
        name="totalAssets"
        type="text"
        error={errors.totalAssets}
        defaultValue={formData.totalAssets}
        required
      />

      <Input
        label="How did you first hear about Little Scholar?"
        name="howFirstHear"
        type="text"
        error={errors.howFirstHear}
        defaultValue={formData.howFirstHear}
        required
      />

      <InputGroup label="Identify single point of contact:">
        <div className="md:flex flex-row md:space-x-4">
          <Input
            label="Name"
            name="contactName"
            type="text"
            error={errors.contactName}
            defaultValue={formData.contactName}
            required
          />

          <Input
            label="Contact Info (Phone or email)"
            name="contactInformation"
            type="text"
            error={errors.contactInformation}
            defaultValue={formData.contactInformation}
            required
          />
        </div>
      </InputGroup>

      <Textarea
        label="Identify best meeting days and time (at least 2 options)"
        name="meetingTime"
        type="text"
        error={errors.meetingTime}
        defaultValue={formData.meetingTime}
        required
      />

      <div className="flex flex-row justify-between">
        <ContainedButton onClick={onBack}>Back</ContainedButton>
        <ContainedButton type="submit">Continue</ContainedButton>
      </div>
    </form>
  )
}
