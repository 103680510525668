import React from 'react'
import Dropdown, { propTypes } from './index'

const YesNoDropdown = props => {
  return (
    <Dropdown {...props}>
      <option value={true}>Yes</option>
      <option value={false}>No</option>
    </Dropdown>
  )
}

YesNoDropdown.propTypes = propTypes

export default YesNoDropdown
