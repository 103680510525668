import React from 'react'
import classNames from 'classnames/bind'

const Textarea = ({
  label,
  rows,
  name,
  placeholder,
  error,
  defaultValue,
  className,
  required,
}) => {
  return (
    <div
      className={classNames(
        'mb-3 focus-within:text-accent-500 text-gray-800',
        className
      )}
    >
      <label htmlFor={name} className="block ml-3 mb-1 font-bold">
        {label}
        {required && <span className="text-red-600">*</span>}
      </label>
      <textarea
        name={name}
        className="block appearance-none w-full py-2 px-3 border-2 border-gray-400 focus:border-accent-400 text-gray-700 focus:outline-none leading-tight rounded-md"
        style={{ minHeight: '6rem' }}
        placeholder={placeholder}
        rows={rows ?? 5}
        defaultValue={defaultValue}
        required={required}
      />
      {error && <p className="ml-3 text-red-900">{error}</p>}
    </div>
  )
}

export default Textarea
